import React from 'react'
import "./About.css"
import about from "./about-img.png"
import { Link } from 'react-router-dom'
const About = () => {
    return (

        <section className="about" id="about">

            <h1 className="heading"> about us </h1>

            <div className="row">

                <div className="image">
                    <img src={about} alt="about" />
                </div>

                <div className="content">
                    <h3 className="title">Find The Right Internship For You!</h3>
                    <p className="lowercase">With personalized reviews of internship experiences for a variety of companies, you can explore different internship positions and opportunities. <br /> <br /> Rate My Internships is an internship review platform that helps students find their dream internship. Students can easily browse reviews from past interns from their favorite companies. Help grow our community by posting a review today!</p>
                    <Link to="/search" className="btn">Search now</Link>
                </div>

            </div>

        </section>

    )
}

export default About
